import React from 'react'

function DevisGratuit() {
  return (
    <div className='devis-gratuit'>
        <a href='#contactUs'>Obtenir un devis gratuitement -&gt;</a>
    </div>
  )
}

export default DevisGratuit