import React, { useEffect, useState } from 'react';
import '../../CSS/components.css';
import logo from '../../img/Logo.png';
import {Button} from '../indexComponents';


function Navbar({isBlurry}) {
    const toggleMenu = () => {
        document.querySelector('.menu-hamburger').classList.toggle('is-open');
        document.querySelector('.navbar').classList.toggle('is-open');
        const links = document.querySelectorAll('.menu-mobile-list li');
        links.forEach((link) => {
            link.classList.toggle('fade');
        });
    };

    const handleScroll = () => {
        if (window.scrollY > 20) {
            document.querySelector('.navbar').classList.add('sticky');
            window.removeEventListener('scroll', handleScroll);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`navbar ${isBlurry ? '' : 'notBlurry'}`}>
            <a href="#accueil">
                <img src={logo} alt="Logo MXB Tech" />
            </a>
            <ul className="navbar-list">
                <li><Button text="ACCUEIL" className="menu-item" route="/" /></li>
                <li><Button text="SERVICES" className="menu-item" route="/#services" /></li>
                <li><Button text="RÉALISATIONS" className="menu-item" route="/#portfolio" /></li>
                {/* <li><Button text="BLOG" className="menu-item" route="/blogsinformatiques" /></li> */}
                <li><Button text="NOUS JOINDRE" className="menu-item" route="/#contactUs" /></li>
            </ul>

            <div className='menu-hamburger' onClick={toggleMenu}>
                <div className='bars bar-1'></div>
                <div className='bars bar-2'></div>
                <div className='bars bar-3'></div>
            </div>

            <div className="menu-mobile">
                <ul className="menu-mobile-list">
                    <li><Button text="ACCUEIL" className="menu-item" route="/" onClick={toggleMenu}/></li>
                    <li><Button text="SERVICES" className="menu-item" route="/#services" onClick={toggleMenu}/></li>
                    <li><Button text="RÉALISATIONS" className="menu-item" route="/#portfolio" onClick={toggleMenu}/></li>
                    {/* <li><Button text="BLOG" className="menu-item" route="/blogsinformatiques" onClick={toggleMenu}/></li> */}
                    <li><Button text="NOUS JOINDRE" className="menu-item" route="/#contactUs" onClick={toggleMenu}/></li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;